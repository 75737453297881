import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // Define Ad unit names
  const RightColumnUnitName = "DESK_RightColumn";
  const LeftColumnUnitName = "DESK_LeftColumn";
  const MPUTopRightUnitName = "MOB_MPUTopRight";
  let LeaderBelowTitleUnitName = "DESK_LeaderBelowTitle";
  if (window.screen.width < 812) {
    LeaderBelowTitleUnitName = "MOB_LeaderBelowTitle";
  }

  // Define Ad Unit Pathways
  const RightColumnPathway = "TVLDE/DESK_RightColumn";
  const LeftColumnPathway = "TVLDE/DESK_LeftColumn";
  let MPUTopRightPathway = "TVLDE/DESK_MPUTopRight";
  let LeaderBelowTitlePathway = "TVLDE/DESK_LeaderBelowTitle";
  if (window.screen.width < 812) {
    MPUTopRightPathway = "TVLDE/MOB_MPUTopRight";
    LeaderBelowTitlePathway = "TVLDE/MOB_LeaderBelowTitle";
  }
  // prettier-ignore
  return [
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeaderBelowTitle",
      "name": LeaderBelowTitleUnitName,
      "path": `/76730613/${LeaderBelowTitlePathway}`,
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 951049 } },
        { bidder: 'appnexus', params: { placementId: '20701255' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 607962, size: [728,90] } },
        // //{ bidder: 'justpremium', params: {zone: 115711} },
        //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
        //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
        { bidder: 'openx', params: { unit: '540973678', delDomain: 'lqr-d.openx.net'} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_next_Leader_bellowTitle' } },
        //{ bidder: 'richaudience', params: { pid: 'QReieyrtGC', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 841690 } },
        { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_NEXT_Leader_bellowTitle_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        // { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116575} },
        { bidder: 'sharethrough', params: {pkey: 'CNH1Fo36FnQNak21HTGs1fIv'} }
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 951158 } },
        { bidder: 'appnexus', params: { placementId: '20701255' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm',  params: { placementId: 16005275 } },
        //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
        { bidder: 'ix', params: {siteId: 607968, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 607968, size: [336,280] } },
        { bidder: 'ix', params: {siteId: 607968, size: [320,100] } },
        // //{ bidder: 'justpremium', params: {zone: 115711} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '540973678', delDomain: 'lqr-d.openx.net'} },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_next_Leader_bellowTitle' } },
        //{ bidder: 'richaudience', params: { pid: '6yQQzMZSfj', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 841689 } },
        //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_mobile_NEXT_Leader_bellowTitle_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        // { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116595} },
        { bidder: 'sharethrough', params: {pkey: 'v233h5pvqZQBNDDGtcDDVINb'} },
        { bidder: 'sharethrough', params: {pkey: 'DgGYBG0o7NEfsUntuVxSsIo6'} }
      ]
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUTopRight",
      "name": MPUTopRightUnitName,
      "path": `/76730613/${MPUTopRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'MPUTopRight', environment:'mobile', placement:`${MPUTopRightPathway}` } },
        { bidder: 'adform', params: { mid: 951058 } },
        { bidder: 'appnexus', params: { placementId: '20701256' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm', params: { placementId: 16005279 } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367605, memberid : 101868 } },
        { bidder: 'ix',  params: { siteId: 607964, size: [300, 250] } },
        { bidder: 'ix',  params: { siteId: 607964, size: [336, 280] } },
        { bidder: 'ix',  params: { siteId: 607964, size: [320, 100] } },
        // //{ bidder: 'justpremium', params: {zone: 115711} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '540973681', delDomain: 'lqr-d.openx.net' } },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_next_MPUTopRight' } },
        //{ bidder: 'richaudience', params: { pid: 'hoS2bUVNvb', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 841694 } },
        //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        { bidder: 'teads', params: { pageId: '113724', placementId: '135707'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_mobile_NEXT_MPUTopRight_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        // { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116581} },
        { bidder: 'sharethrough', params: {pkey: 'VKJATx3lhdO50dK2xLzzO88D'} },
        { bidder: 'sharethrough', params: {pkey: 'WfdV85iYP41tw7s1YkmbHGyY'} }
      ]
    },
    {
      "condition": window.screen.width > 812,
      "id": "RightColumn",
      "name": RightColumnUnitName,
      "path": `/76730613/${RightColumnPathway}`,
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'RightColumn', environment:'desktop', placement:`${RightColumnPathway}` } },
        { bidder: 'adform', params: { mid: 951051 } },
        { bidder: 'appnexus', params: { placementId: '20701259'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm', params: { placementId: 16005296 } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
        { bidder: 'ix', params: { siteId: 607960, size: [160, 600] } },
        // //{ bidder: 'justpremium', params: {zone: 115711} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '540973682', delDomain: 'lqr-d.openx.net'} },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_next_RightColumnHalfPage' } },
        //{ bidder: 'richaudience', params: { pid: 'HOpAKTXHmc', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 841695 } },
        { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
        { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersDE_NEXT_RightColumnHalfPage_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        // { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116582} },
        { bidder: 'sharethrough', params: {pkey: 'PEK2nvmwbb4V9cmkWXltBwcs'} }
      ]
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeftColumn",
      "name": LeftColumnUnitName,
      "path": `/76730613/${LeftColumnPathway}`,
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeftColumn', environment:'desktop', placement:`${LeftColumnPathway}` } },
        { bidder: 'adform', params: { mid: 994357 } },
        { bidder: 'appnexus', params: { placementId: '21164981'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: { siteId: 729204, size: [160, 600] } },
        ////{ bidder: 'justpremium', params: {zone: 115711, allow: ["sa"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '543829668', delDomain: 'lqr-d.openx.net'} },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_next_LeftColumnHalfPage' } },
        { bidder: 'sovrn', params:{ tagid: 866552 } },
        { bidder: 'sovrn', params:{ tagid: 935371 } },
        { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
        { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersDE_desktop_NEXT_LeftColumnHalfPage_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        // { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'LeftColumn', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116578} },
        { bidder: 'sharethrough', params: {pkey: 'S3kzul4XVlhLn7IO265faI29'} }
      ]
    }
  ];
}
