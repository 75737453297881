import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // prettier-ignore
  const bidders = [
    { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:'TVLDE/DESK_NEXT_LeaderBelowTitle' } },
    { bidder: 'adform', params: { mid: 951049 } },
    { bidder: 'appnexus', params: { placementId: '20701255' } },
    { bidder: 'criteo', params: { networkId: 8147 } },
    { bidder: 'ix', params: {siteId: 607962, size: [728,90] } },
    // { bidder: 'justpremium', params: {zone: 115711} },
    //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
    //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
    { bidder: 'openx', params: { unit: '540973678', delDomain: 'lqr-d.openx.net'} },
    { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
    // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_next_Leader_bellowTitle' } },
    //{ bidder: 'richaudience', params: { pid: 'QReieyrtGC', supplyType: 'site' }},
    { bidder: 'sovrn', params:{ tagid: 841690 } },
    { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
    { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_NEXT_Leader_bellowTitle_Prebid"} },
    { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
    // { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
    { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
    { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
    { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116575} },
    { bidder: 'sharethrough', params: {pkey: 'CNH1Fo36FnQNak21HTGs1fIv'} }
  ];

  // prettier-ignore
  const mobileBidders = [
    { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:'TVLDE/MOB_NEXT_LeaderBelowTitle' } },
    { bidder: 'adform', params: { mid: 951158 } },
    { bidder: 'appnexus', params: { placementId: '20701255' } },
    { bidder: 'criteo', params: { networkId: 8147 } },
    //{ bidder: 'districtm',  params: { placementId: 16005275 } },
    //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
    { bidder: 'ix', params: {siteId: 607968, size: [300,250] } },
    { bidder: 'ix', params: {siteId: 607968, size: [336,280] } },
    { bidder: 'ix', params: {siteId: 607968, size: [320,100] } },
    // { bidder: 'justpremium', params: {zone: 115711} },
    { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
    { bidder: 'openx', params: { unit: '540973678', delDomain: 'lqr-d.openx.net'} },
    // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_next_Leader_bellowTitle' } },
    //{ bidder: 'richaudience', params: { pid: '6yQQzMZSfj', supplyType: 'site' }},
    { bidder: 'sovrn', params:{ tagid: 841689 } },
    //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
    { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
    { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_mobile_NEXT_Leader_bellowTitle_Prebid"} },
    { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
    // { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
    { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
    { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
    { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116595} },
    { bidder: 'sharethrough', params: {pkey: 'v233h5pvqZQBNDDGtcDDVINb'} },
    { bidder: 'sharethrough', params: {pkey: 'DgGYBG0o7NEfsUntuVxSsIo6'} }
  ];
  // prettier-ignore
  let adUnits = [
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100], [1,1]] : [[728, 90], [1,1]],
        "bidders": bidders,
        "mobile_bidders": mobileBidders,
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle_1",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
        "mobile_bidders": mobileBidders,
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle_2",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
        "mobile_bidders": mobileBidders,
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle_4",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
        "mobile_bidders": mobileBidders,
      },
      {
        "condition": window.screen.width > 812,
        "id": "RightColumn",
        "name": "NEXT_RightColumnHalfPage1",
        "path": "/76730613/NEXT_RightColumnHalfPage1",
        "sizes": window.screen.width > 1440 ? [[300, 600]] : [[160, 600]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'RightColumn', environment:'desktop', placement:'TVLDE/DESK_NEXT_RightColumn' } },
          { bidder: 'adform', params: { mid: 951051 } },
          { bidder: 'appnexus', params: { placementId: '20701259'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'districtm', params: { placementId: 16005296 } },
          //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
          { bidder: 'ix', params: { siteId: 607960, size: [160, 600] } },
          // { bidder: 'justpremium', params: {zone: 115711} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '540973682', delDomain: 'lqr-d.openx.net'} },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_next_RightColumnHalfPage' } },
          //{ bidder: 'richaudience', params: { pid: 'HOpAKTXHmc', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 841695 } },
          { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
          { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersDE_NEXT_RightColumnHalfPage_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          // { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116582} },
          { bidder: 'sharethrough', params: {pkey: 'PEK2nvmwbb4V9cmkWXltBwcs'} }
        ]
      },
    ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width > 1440) {
      let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
      rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 607960, size:[300, 600]} });
    }
  return adUnits;
}
