import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }
  const abtest = window.localStorage.getItem("abtest");

  // Define Ad unit names
  // Original
  let RightColumnUnitName = "RightColumn1";
  let LeftColumnUnitName = "LeftColumnHalfPage";
  let LeaderDesktopInfiniteUnitName = "LeaderDesktopInfinite";
  let MPUCenterRightUnitName = "MPUCenterRight";
  let MPUTopRightUnitName = "MPUTopRight";
  let LeaderBelowTitleUnitName = "Leader_BellowTitle";
  let StickyBannerUnitName = "StickyBannerDesktop";
  let MPUParallaxUnitName = "MPU_Parallax";
  if (window.screen.width < 812) {
    StickyBannerUnitName = "StickyBannerMobile";
  }

  // Domain/device Specific
  if (abtest === "ab.tvl.de.y") {
    RightColumnUnitName = "DESK_RightColumn";
    LeftColumnUnitName = "DESK_LeftColumn";
    LeaderDesktopInfiniteUnitName = "DESK_LeaderDesktopInfinite";
    MPUCenterRightUnitName = "MOB_MPUCenterRight";
    MPUTopRightUnitName = "MOB_MPUTopRight";
    LeaderBelowTitleUnitName = "DESK_LeaderBelowTitle";
    StickyBannerUnitName = "DESK_StickyBannerDesktop";
    MPUParallaxUnitName = "MOB_MPUParallax";
    if (window.screen.width < 812) {
      LeaderBelowTitleUnitName = "MOB_LeaderBelowTitle";
      StickyBannerUnitName = "MOB_StickyBanner";
    }
  }

  // Define Ad Unit Pathways
  // Original
  let RightColumnPathway = "RightColumn1";
  let LeftColumnPathway = "LeftColumnHalfPage";
  let LeaderDesktopInfinitePathway = "LeaderDesktopInfinite";
  let MPUCenterRightPathway = "MPUCenterRight";
  let MPUTopRightPathway = "MPUTopRight";
  let LeaderBelowTitlePathway = "Leader_BellowTitle";
  let StickyBannerPathway = "StickyBannerDesktop";
  let MPUParallaxPathway = "MPU_Parallax";
  if (window.screen.width < 812) {
    StickyBannerPathway = "StickyBannerMobile";
  }

  // Domain/device Specific
  if (abtest === "ab.tvl.de.y") {
    RightColumnPathway = "TVLDE/DESK_RightColumn";
    LeftColumnPathway = "TVLDE/DESK_LeftColumn";
    LeaderDesktopInfinitePathway = "TVLDE/DESK_LeaderDesktopInfinite";
    MPUCenterRightPathway = "TVLDE/DESK_MPUCenterRight";
    MPUTopRightPathway = "TVLDE/DESK_MPUTopRight";
    LeaderBelowTitlePathway = "TVLDE/DESK_LeaderBelowTitle";
    StickyBannerPathway = "TVLDE/DESK_StickyBannerDesktop";
    if (window.screen.width < 812) {
      MPUTopRightPathway = "TVLDE/MOB_MPUTopRight";
      MPUCenterRightPathway = "TVLDE/MOB_MPUCenterRight";
      LeaderBelowTitlePathway = "TVLDE/MOB_LeaderBelowTitle";
      StickyBannerPathway = "TVLDE/MOB_StickyBannerMobile";
      MPUParallaxPathway = "TVLDE/MOB_MPUParallax";
    }
  }
  // prettier-ignore
  let adUnits = [
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      "id": "LeaderBelowTitle",
      "name": LeaderBelowTitleUnitName,
      "path": `/76730613/${LeaderBelowTitlePathway}`,
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 951175 } },
        { bidder: 'appnexus', params: { placementId: '18453929' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: {siteId: 467040, size: [728,90] } },
        // //{ bidder: 'justpremium', params: {zone: 115711} },
        //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
        //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
        { bidder: 'openx', params: { unit: '540973678', delDomain: 'lqr-d.openx.net'} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_Leader_bellowTitle' } },
        //{ bidder: 'richaudience', params: { pid: 'f2gYmYFlWp', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 793280 } },
        { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_Leader_bellowTitle_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116569} },
        { bidder: 'sharethrough', params: {pkey: 'OWyFs0f1VrEBZOq0brmY89Ud'} }
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 951054 } },
        { bidder: 'appnexus', params: { placementId: '18453929' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm',  params: { placementId: 16005275 } },
        //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
        { bidder: 'ix', params: {siteId: 467046, size: [300,250] } },
        { bidder: 'ix', params: {siteId: 467046, size: [336,280] } },
        { bidder: 'ix', params: {siteId: 467046, size: [320,100] } },
        // //{ bidder: 'justpremium', params: {zone: 115711} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '540973678', delDomain: 'lqr-d.openx.net'} },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_Leader_bellowTitle' } },
        //{ bidder: 'richaudience', params: { pid: 'MflbnfZv1Z', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 795456 } },
        { bidder: 'teads', params: { pageId: '113724', placementId: '138893'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_mobile_Leader_bellowTitle_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116587} },
        { bidder: 'sharethrough', params: {pkey: 'ONgb5QVSSl8lE7VNnxtdWZPo'} },
        { bidder: 'sharethrough', params: {pkey: '1F9KIUylIIrwcAeBTDbZhu8K'} }
      ]
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUTopRight",
      "name": MPUTopRightUnitName,
      "path": `/76730613/${MPUTopRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'MPUTopRight', environment:'mobile', placement:`${MPUTopRightPathway}` } },
        { bidder: 'adform', params: { mid: 951057 } },
        { bidder: 'appnexus', params: { placementId: '18453930' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm', params: { placementId: 16005279 } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367605, memberid : 101868 } },
        { bidder: 'ix',  params: { siteId: 467042, size: [300, 250] } },
        { bidder: 'ix',  params: { siteId: 467042, size: [336, 280] } },
        { bidder: 'ix',  params: { siteId: 467042, size: [320, 100] } },
        // //{ bidder: 'justpremium', params: {zone: 115711} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '540973681', delDomain: 'lqr-d.openx.net' } },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_MPUTopRight' } },
        //{ bidder: 'richaudience', params: { pid: '3ddS57sC8l', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 793281 } },
        { bidder: 'teads', params: { pageId: '113724', placementId: '135709'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_mobile_MPUTopRight_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116574} },
        { bidder: 'sharethrough', params: {pkey: 'mhQ2yKVSvkU9XvV9oYziTgjw'} },
        { bidder: 'sharethrough', params: {pkey: 'KkVmT9L0nA9Pb2jA60GzdT6c'} }
      ]
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('fb-'),
      "id": "RightColumn",
      "name": RightColumnUnitName,
      "path": `/76730613/${RightColumnPathway}`,
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'RightColumn', environment:'desktop', placement:`${RightColumnPathway}` } },
        { bidder: 'adform', params: { mid: 951053 } },
        { bidder: 'appnexus', params: { placementId: '18453938'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm', params: { placementId: 16005296 } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
        { bidder: 'ix', params: { siteId: 467038, size: [160, 600] } },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '540973682', delDomain: 'lqr-d.openx.net'} },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_RightColumnHalfPage' } },
        //{ bidder: 'richaudience', params: { pid: 'Tyc2Nas1Lm', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 793283 } },
        { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
        { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersDE_RightColumnHalfPage_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116584} },
        { bidder: 'sharethrough', params: {pkey: 'PEK2nvmwbb4V9cmkWXltBwcs'} },
        { bidder: 'sharethrough', params: {pkey: 'gsyKkiWJcK3yG8oSEkEn7MTk'} },
        { bidder: 'sharethrough', params: {pkey: 'dK4CNP6Vg1E8taq8MohySedi'} },
        
        
      ]
      
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('yahoo_') && !checkUtmSourceStartsWith('fb-'),
      "id": "LeftColumn",
      "name": LeftColumnUnitName,
      "path": `/76730613/${LeftColumnPathway}`,
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeftColumn', environment:'desktop', placement:`${LeftColumnPathway}` } },
        { bidder: 'adform', params: { mid: 994514 } },
        { bidder: 'appnexus', params: { placementId: '21164982'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: { siteId: 729203, size: [160, 600] } },
        ////{ bidder: 'justpremium', params: {zone: 115711, allow: ["sa"]}},
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '541076639', delDomain: 'lqr-d.openx.net'} },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_LeftColumn' } },
        { bidder: 'sovrn', params:{ tagid: 866553 } },
        { bidder: 'sovrn', params:{ tagid: 935370 } },
        { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
        { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersDE_desktop_LeftColumnHalfPage_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'LeftColumn', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116589} },
        { bidder: 'sharethrough', params: {pkey: 'Ttm4RA1KRE0W7Um0WcX3IIEp'} },
        { bidder: 'sharethrough', params: {pkey: 'yZFxrdi8JwwNjtLOMpMshc5P'} },
      ],
    },
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "StickyBanner",
      "name": StickyBannerUnitName,
      "path": `/76730613/${StickyBannerPathway}`,
      "sizes": window.screen.width < 812 ? [[320, 50],[300,50],[360,50],[320,100],[300,100],[360,100]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'StickyBannerDesktop', environment:'desktop', placement:`${StickyBannerPathway}` } },
        { bidder: 'adform', params: { mid: 1090088 } },
        { bidder: 'appnexus', params: { placementId: '22604766'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: { siteId: 467047, size: [728, 90] } },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        //{ bidder: 'justpremium', params: {zone: 115711, allow: ["ms"]} },
        { bidder: 'openx', params: { unit: '540973684', delDomain: 'lqr-d.openx.net'} },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_StickyBanner' } },
        { bidder: 'sovrn', params:{ tagid: 938343 }},
        { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
        { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersDE_desktop_StickyBannerDesktop_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116585} },
        { bidder: 'sharethrough', params: {pkey: 'K6JZhqSzN6a3isdRmPJnogC1'} }
        
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'StickyBanner', environment:'mobile', placement:'StickybannerMobile' } },
        { bidder: 'adform', params: { mid: 951068 } },
        { bidder: 'appnexus', params: { placementId: '18453937' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm', params: { placementId: 18069184} },
        //{ bidder: 'districtmDMX',params: {dmxid: 479166, memberid: 101868} },
        { bidder: 'ix', params: {siteId: 467049, size: [320,50]} },
        { bidder: 'ix', params: {siteId: 467049, size: [300,50]} },
        { bidder: 'ix', params: {siteId: 467049, size: [360,50]} },
        //{ bidder: 'justpremium', params: {zone: 115711, allow: ["ms"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '540973687', delDomain: 'lqr-d.openx.net' } },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_StickyBannerMobile' } },
        //{ bidder: 'richaudience', params: { pid: 'lGnoSGAeWf', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 793278 } },
        { bidder: 'teads', params: {pageId: '113724', placementId: '131125'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_StickyBannerMobile_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116601} },
        { bidder: 'sharethrough', params: {pkey: 'xGxNcSl8oxzJQgbE1Ejsm7tE'} },
        { bidder: 'sharethrough', params: {pkey: 'wmtGmovqOoLetRyABcgvh4qm'} }
      ]
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUCenterRight",
      "name": MPUCenterRightUnitName,
      "path": `/76730613/${MPUCenterRightPathway}`,
      "sizes": [[300, 250],[320,100],[336,280], [1,1]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'MPUCenterRight', environment:'mobile', placement:`${MPUCenterRightPathway}` } },
        { bidder: 'adform', params: { mid: 951055 } },
        { bidder: 'appnexus', params: { placementId: '18453931' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix',  params: { siteId: 467045, size: [300, 250] } },
        { bidder: 'ix',  params: { siteId: 467045, size: [336, 280] } },
        { bidder: 'ix',  params: { siteId: 467045, size: [320, 100] } },
        //{ bidder: 'justpremium', params: {zone: 115711, allow: ["is"]} },
        //{ bidder: 'districtm',  params: { placementId: 16005292 } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367606, memberid : 101868 } },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '540973680', delDomain: 'lqr-d.openx.net' } },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_MPUCenterRight' } },
        //{ bidder: 'richaudience', params: { pid: 'woUXWEE7sU', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 793282 } },
        { bidder: 'teads', params: { pageId: '113724', placementId: '135709'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_mobile_MPUCenterRight_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        //{ bidder: 'ogury', params: { assetKey: 'OGY-831F435F9475', adUnitId: 'c7c52770-17e3-013b-8434-3794722aeb85', skipSizeCheck: true } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116603} },
        { bidder: 'sharethrough', params: {pkey: 'RcqiNHxBudC5cNJu5evEpYfX'} },
        { bidder: 'sharethrough', params: {pkey: 'UUpAslailk8B15lAAHQm7xRO'} }
      ]
    },
    {
      "condition": window.screen.width > 812,
      "id": "LeaderDesktopInfinite",
      "name": LeaderDesktopInfiniteUnitName,
      "path": `/76730613/${LeaderDesktopInfinitePathway}`,
      "sizes": [[728,90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeaderDesktopInfinite', environment:'desktop', placement:`${LeaderDesktopInfinitePathway}` } },
        { bidder: 'adform', params: { mid: 994515 } },
        { bidder: 'appnexus', params: { placementId: '21164983'} },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'ix', params: { siteId: 509584, size: [728, 90] } },
        // //{ bidder: 'justpremium', params: {zone: 115711} },
        //{ bidder: 'districtm', params: { placementId: 19406832} },
        //{ bidder: 'districtmDMX', params: { dmxid: 526652, memberid : 101868 } },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '541076957', delDomain: 'lqr-d.openx.net'} },
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_LeaderDesktopInfinite' } },
        //{ bidder: 'richaudience', params: { pid: 'S6h9jcYHz4', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 866554 }},
        { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
        { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersDE_desktop_LeaderDesktopInfinite_Prebid"} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'LeaderDesktopInfinite', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116570} },
        { bidder: 'sharethrough', params: {pkey: 'pvTHa9rpqJv6yMg8uTGvWa9w'} }
      ],
    },
    {
      "condition": false,
      "id": "MPU_Parallax",
      "name": MPUParallaxUnitName,
      "path": `/76730613/${MPUParallaxPathway}`,
      "sizes": [[300,600], [300,250], [336,280], [320,50], [160,600], [120,600], [320,480]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'MPU_Parallax', environment:'mobile', placement:'TVLDE/MOB_MPUParallax' } },
        { bidder: 'adform', params: { mid: 1182053 } },
        { bidder: 'appnexus', params: { placementId: '23075453' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        { bidder: 'openx', params: { unit: '556399856', delDomain: 'lqr-d.openx.net' } },
        { bidder: 'teads', params: {pageId: '113724', placementId: '131125'} },
        { bidder: 'ix', params: {siteId: 752392, size: [300,250]} },
        { bidder: 'ix', params: {siteId: 752392, size: [320,480]} },
        { bidder: 'ix', params: {siteId: 752392, size: [336,280]} },
        { bidder: 'ix', params: {siteId: 752392, size: [300,600]} },
        { bidder: 'ix', params: {siteId: 752392, size: [160,600]} },
        { bidder: 'ix', params: {siteId: 752392, size: [320,50]} },
        ////{ bidder: 'justpremium', params: {zone: 133775, allow: ["is"]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'sovrn', params:{ tagid: 972314 }},
        { bidder: 'sovrn', params:{ tagid: 972315 }},
        { bidder: 'sovrn', params:{ tagid: 972316 }},
        { bidder: 'sovrn', params:{ tagid: 972317 }},
        { bidder: 'sovrn', params:{ tagid: 972318 }},
        //{ bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'de_mobile_MPUInfiniteMobile' } },
        { bidder: 'triplelift', params: {inventoryCode: 'MotormagCOM_mobile_MPUParallax_Prebid'} },
        //{ bidder: 'yieldone', params: {placementId: '133371'} },
        { bidder: 'sharethrough', params: {pkey: 'T6ya68IIyE4a10Bq1diHUc2S'} },
        { bidder: 'sharethrough', params: {pkey: 'aKTFGkutZsa5TcEEj9G6i1sV'} },
        { bidder: 'sharethrough', params: {pkey: 's08UIhL9W9sUopu3Qfoo3HLb'} },
        { bidder: 'sharethrough', params: {pkey: 'DmqnbKIGUo81q2AeMZu3O5b1'} },
        { bidder: 'sharethrough', params: {pkey: 'CR4940i5OputuO7PVAOel99h'} },
        { bidder: 'sharethrough', params: {pkey: 'ZlqHdllkxFVaiaBX9GSUeQAk'} },
        { bidder: 'sharethrough', params: {pkey: 'VnWph7vUj6Da9cNQMEvSyPoZ'} },
        { bidder: 'sharethrough', params: {pkey: 'qdJzy9KVl71R2YNdVRBqSIdI'} },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'taboola', params: { tagId: 'MPU_Parallax', publisherId: '1493286'} },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116602} }
      ],
        
    }
  ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width > 1440) {
    let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
    let lcMissingIX = adUnits.find(ad => ad.id === 'LeftColumn');
    // Right
    rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 467038, size:[300, 600]} });
    rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 467038, size:[300, 250]} });
    rcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103057 } });
    // Left
    lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 729203, size:[300, 600]} });
    lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 729203, size:[300, 250]} });
    lcMissingIX.bidders.push({ bidder: 'sharethrough', params: {pkey: 'gZPXazeWPWtx7o4lPNx7uJ1y'} });
    lcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103059 } });
  }
  return adUnits;
}
