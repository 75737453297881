import { TaboolaROAS } from "../components/taboola-roas";
import { DebugModeLog } from "../utils";
import { loadScript, loadNoScript } from "../utils";

export const CPMEvents = (won_data) => {
  check_hb_bids(won_data);

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function send_source_cpm_event(cpm_total, prefix, cookie_goal_name) {
    var val_goal_cookie = getCookie(cookie_goal_name);
    var val_goal = window._min_uv_goal;
    if (val_goal_cookie && parseInt(val_goal_cookie) > 0) {
      val_goal = parseInt(val_goal_cookie);
    }
    console.log(val_goal);
    console.log(cpm_total);

    while (
      val_goal <= cpm_total / 100 &&
      val_goal >= window._min_uv_goal &&
      val_goal <= window._max_uv_goal
    ) {
      send_CPM_event_all_sources(
        parseFloat(val_goal),
        prefix,
        cookie_goal_name
      );
      console.log("here");
      if (val_goal <= 100) {
        val_goal += 5;
      } else if (val_goal <= 300) {
        val_goal += 20;
      } else {
        val_goal += 50;
      }
    }
    setCookie(cookie_goal_name, val_goal);
    return val_goal;
  }
  function send_CPM_event_all_sources(cpm, prefix, cookie_goal_name) {
    inject_tb_event_code(cpm, prefix, cookie_goal_name);
  }
  function inject_tb_event_code(cpm, prefix, cookie_goal_name) {
    var stringifiedCPM = parseFloat(cpm * 0.1)
      .toString()
      .replace(".", "");

    window._tfa.push({
      notify: "event",
      name: "UV" + stringifiedCPM,
      id: 1052791,
    });
    window._tfa.push({
      notify: "event",
      name: "UV" + stringifiedCPM,
      id: 1182643,
    });
    window._tfa.push({
      notify: "event",
      name: "UV" + stringifiedCPM,
      id: 1286057,
    });
    window._tfa.push({
      notify: "event",
      name: "UV" + stringifiedCPM,
      id: 1292753,
    });
    window._tfa.push({
      notify: "event",
      name: "UV" + stringifiedCPM,
      id: 1274783,
    });
    console.log("tb v g P, bzh cur");
    console.log(stringifiedCPM);
    window.gtag("event", "conversion", {
      send_to: "AW-793126717/9xIuCJ2bmr0BEL3OmPoC",
    });
    window.gtag("event", "conversion", {
      send_to: "AW-573322620/93n8CJu0nuEBEPzqsJEC",
    });

    if (stringifiedCPM === "2") {
      window.gtag("event", "conversion", {
        send_to: "AW-11127303233/bjjMCL-H9JgYEMHY9Lkp",
      });

      window.twq("event", "tw-o78mz-offvj", {});

      loadScript(
        "",
        true,
        true,
        false,
        `
                window._megoaa = window._megoaa || [];
                window._megoaa.push({ type: 'event', name: 'pageview'});
                window._megoaa.push({ type: 'nextjump', link: ""});
                !function (t, f, a, x) {
                        if (!document.getElementById(x)) {
                            t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
                        }
                }(document.createElement('script'),
                document.getElementsByTagName('script')[0], '//cdn.mediago.io/js/pixel.js', 'pixel_megoaa_script');
                `
      );
      loadNoScript(
        true,
        `
                <img src='//trace.mediago.io/api/bidder/track/pixel/pageview' width='0' height='0' style='display:none' />
                `
      );
      loadScript(
        "",
        true,
        true,
        false,
        `
                window._megoaa = window._megoaa || [];
                window._megoaa.push({ type: 'event', name: 'UV2', acid: '22980', pxd: '606574636698801'});
                `
      );
      loadNoScript(
        true,
        `
                <img src='//trace.mediago.io/api/bidder/track/pixel/conversion?cvn=UV2&acid=22980&pxd=606574636698801' width='0' height='0' style='display:none' />
                `
      );
    }

    if (stringifiedCPM === "3") {
      window.fbq("trackCustom", `UserValue3`, { Value: "UV3" });
    }
    if (stringifiedCPM === "6") {
      window.fbq("trackCustom", `UserValue6`, { Value: "UV6" });
    }
    if (stringifiedCPM === "10") {
      window.fbq("trackCustom", `UserValue10`, { Value: "UV10" });
    }
  }
  //var window.sumCpmEvent = 0;

  // function setCookiewDays(cname, cvalue, exdays) {
  //     var d = new Date();
  //     d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  //     var expires = "expires=" + d.toUTCString();
  //     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  // }
  function setCookie(cookie_name, cVal) {
    var expDate = new Date();
    expDate.setMinutes(expDate.getMinutes() + 30);
    let cookie = cookie_name + "=" + cVal + ";path=/";
    document.cookie = cookie;
  }
  function check_hb_bids(won_data) {
    var allResponses = window.pbjs.getBidResponses();
    var winningBids = window.pbjs.getAllWinningBids();
    var current_winner_bids = {};
    var high_bids = window.pbjs.getHighestCpmBids();
    var sumCurrentCpmEvent = 0;
    var sumCurrentGA = 0;
    for (var i = 0; i < winningBids.length; i++) {
      if (!window._winners_calc.hasOwnProperty(winningBids[i]["adUnitCode"])) {
        window._winners_calc[winningBids[i]["adUnitCode"]] = {};
      }
      if (
        window._winners_calc[winningBids[i]["adUnitCode"]].hasOwnProperty(
          winningBids[i]["adId"]
        )
      ) {
        continue;
      }
      current_winner_bids[winningBids[i]["adUnitCode"]] = winningBids[i];
      window._winners_calc[winningBids[i]["adUnitCode"]][
        winningBids[i]["adId"]
      ] = 1;
    }

    for (var adUnitCode in current_winner_bids) {
      if (!document.getElementById(adUnitCode)) {
        continue;
      }
      var bid = "";
      if (current_winner_bids.hasOwnProperty(adUnitCode)) {
        bid = current_winner_bids[adUnitCode];
        var auction_key = adUnitCode + "##" + bid["auctionId"];
        if (window._auctions_calc.hasOwnProperty(auction_key)) {
          window.sumCpmEvent -= window._auctions_calc[auction_key];
          window.bids_added -= 1;
        }
        // let data = "adunit:" + bid["adUnitCode"] + " code:" + bid["bidderCode"] + " cpm:" + bid["cpm"] + " timeout:" + bid["timeToRespond"];
        //console.log('cpm before ' + parseFloat(bid["cpm"]));
        let cpm = Math.floor(parseFloat(bid["cpm"]) * 100);
        window.sumCpmEvent += cpm;
        window.sumCpmEvent_hb += cpm;
        sumCurrentCpmEvent += cpm;
        window.bids_added += 1;
        window._auctions_calc[auction_key] = cpm;
      }
    }

    for (var adUnitCodeResponse in allResponses) {
      if (!document.getElementById(adUnitCodeResponse)) {
        continue;
      }
      if (
        !allResponses[adUnitCodeResponse].hasOwnProperty("bids") ||
        allResponses[adUnitCodeResponse]["bids"].length === 0
      ) {
        continue;
      }
      var auction_id = allResponses[adUnitCodeResponse]["bids"][0]["auctionId"];
      auction_key = adUnitCodeResponse + "##" + auction_id;
      if (window._auctions_calc.hasOwnProperty(auction_key)) {
        continue;
      }
      let cpm = 0.1 * 100;
      var has_bid = false;
      var factor = 1.1;
      for (var j = 0; j < high_bids.length; j++) {
        if (high_bids[j]["adUnitCode"] === adUnitCodeResponse) {
          //var h_data = "adunit:" + high_bids[j]["adUnitCode"] + " code:" + high_bids[j]["bidderCode"] + " cpm:" + high_bids[j]["cpm"] + " timeout:" + high_bids[j]["timeToRespond"];
          cpm = Math.floor(parseFloat(high_bids[j]["cpm"]) * factor * 100);
          has_bid = true;
        }
      }
      if (!has_bid) {
        window.sumCpmEvent += cpm;
        sumCurrentGA += cpm;
        sumCurrentCpmEvent += cpm;
        window.bids_added += 1;
      } else {
        window.sumCpmEvent += cpm;
        // eslint-disable-next-line no-unused-vars
        sumCurrentGA += cpm;
        // eslint-disable-next-line no-unused-vars
        sumCurrentCpmEvent += cpm;
        window.bids_added += 1;
      }
      window._auctions_calc[auction_key] = cpm;
    }
    setCookie("sumCpmEvent", window.sumCpmEvent);
    setCookie("sumCpmEvent_hb", window.sumCpmEvent_hb);
    // eslint-disable-next-line no-unused-vars
    let _val_goal = send_source_cpm_event(
      window.sumCpmEvent,
      "val_",
      "val_goal"
    );

    //Add listerner for end of session to calculate ROI
    window.addEventListener("beforeunload", (event) => {
      const taboolaROAS = TaboolaROAS();
      const taboolaCPM = window.sumCpmEvent / 1000;
      const taboolaROI = (taboolaCPM / taboolaROAS) * 100;
      const roundTaboola = Math.round(taboolaROI / 10) * 10;

      DebugModeLog("CPM: " + taboolaCPM);
      DebugModeLog("ROI: " + roundTaboola);

      if (roundTaboola >= 130) {
        if (roundTaboola >= 160) {
          window._tfa.push({ notify: "event", name: "ROI160", id: 1182643 });
        } else {
          window._tfa.push({
            notify: "event",
            name: "ROI" + roundTaboola,
            id: 1182643,
          });
        }
      }
    });
  }
};
