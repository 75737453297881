import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const abtest = window.localStorage.getItem("abtest");
  // Define Ad unit names
  // Original
  let RightColumnUnitName = "NEXT_RightColumnHalfPage1";
  let LeftColumnUnitName = "NEXT_LeftColumnHalfPage";
  let MPUCenterRightUnitName = "NEXT_MPUCenterRight";
  let MPUTopRightUnitName = "NEXT_MPUTopRight";
  let LeaderBelowTitleUnitName = "NEXT_Leader_BellowTitle";
  let StickyBannerUnitName = "NEXT_StickyBannerDesktop";
  let MPUBelowNextUnitName = "NEXT_MPUBelowNext";
  if (window.screen.width < 812) {
    StickyBannerUnitName = "NEXT_StickyBannerMobile";
  }
  // Define Ad unit names
  if (abtest === "ab.tvl.de.y") {
    RightColumnUnitName = "DESK_NEXT_RightColumn";
    LeftColumnUnitName = "DESK_NEXT_LeftColumn";
    MPUTopRightUnitName = "MOB_NEXT_MPUTopRight";
    MPUCenterRightUnitName = "DESK_NEXT_MPUCenterRight";
    LeaderBelowTitleUnitName = "DESK_NEXT_LeaderBelowTitle";
    StickyBannerUnitName = "DESK_NEXT_StickyBannerDesktop";
    MPUBelowNextUnitName = "DESK_NEXT_MPUBelowNext";
    if (window.screen.width < 812) {
      LeaderBelowTitleUnitName = "MOB_NEXT_LeaderBelowTitle";
      StickyBannerUnitName = "MOB_NEXT_StickyBanner";
      MPUBelowNextUnitName = "MOB_NEXT_MPUBelowNext";
      MPUCenterRightUnitName = "MOB_NEXT_MPUCenterRight";
    }
  }

  // Define Ad Unit Pathways
  // Original
  let RightColumnPathway = "NEXT_RightColumnHalfPage1";
  let LeftColumnPathway = "NEXT_LeftColumnHalfPage";
  let MPUCenterRightPathway = "NEXT_MPUCenterRight";
  let MPUTopRightPathway = "NEXT_MPUTopRight";
  let LeaderBelowTitlePathway = "NEXT_Leader_BellowTitle";
  let StickyBannerPathway = "NEXT_StickyBannerDesktop";
  let MPUBelowNextPathway = "NEXT_MPUBelowNext";
  if (window.screen.width < 812) {
    StickyBannerPathway = "NEXT_StickyBannerMobile";
  }

  // Define Ad Unit Pathways
  if (abtest === "ab.tvl.de.y") {
    RightColumnPathway = "TVLDE/DESK_NEXT_RightColumn";
    LeftColumnPathway = "TVLDE/DESK_NEXT_LeftColumn";
    MPUCenterRightPathway = "TVLDE/DESK_NEXT_MPUCenterRight";
    MPUTopRightPathway = "TVLDE/DESK_NEXT_MPUTopRight";
    LeaderBelowTitlePathway = "TVLDE/DESK_NEXT_LeaderBelowTitle";
    StickyBannerPathway = "TVLDE/DESK_NEXT_StickyBannerDesktop";
    MPUBelowNextPathway = "TVLDE/DESK_NEXT_MPUBelowNext";
    if (window.screen.width < 812) {
      MPUTopRightPathway = "TVLDE/MOB_NEXT_MPUTopRight";
      MPUCenterRightPathway = "TVLDE/MOB_NEXT_MPUCenterRight";
      LeaderBelowTitlePathway = "TVLDE/MOB_NEXT_LeaderBelowTitle";
      StickyBannerPathway = "TVLDE/MOB_NEXT_StickyBannerMobile";
      MPUBelowNextPathway = "TVLDE/MOB_NEXT_MPUBelowNext";
    }
  }

  // prettier-ignore
  let adUnits = [
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle",
        "name": LeaderBelowTitleUnitName,
        "path": `/76730613/${LeaderBelowTitlePathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:`${LeaderBelowTitlePathway}` } },
          { bidder: 'adform', params: { mid: 951049 } },
          { bidder: 'appnexus', params: { placementId: '20701255' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 607962, size: [728,90] } },
          // //{ bidder: 'justpremium', params: {zone: 115711} },
          //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
          //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
          { bidder: 'openx', params: { unit: '540973678', delDomain: 'lqr-d.openx.net'} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_next_Leader_bellowTitle' } },
          //{ bidder: 'richaudience', params: { pid: 'QReieyrtGC', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 841690 } },
          { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_NEXT_Leader_bellowTitle_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116575} },
          { bidder: 'sharethrough', params: {pkey: 'CNH1Fo36FnQNak21HTGs1fIv'} }
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
          { bidder: 'adform', params: { mid: 951158 } },
          { bidder: 'appnexus', params: { placementId: '20701255' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'districtm',  params: { placementId: 16005275 } },
          //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
          { bidder: 'ix', params: {siteId: 607968, size: [300,250] } },
          { bidder: 'ix', params: {siteId: 607968, size: [336,280] } },
          { bidder: 'ix', params: {siteId: 607968, size: [320,100] } },
          // //{ bidder: 'justpremium', params: {zone: 115711} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '540973678', delDomain: 'lqr-d.openx.net'} },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_next_Leader_bellowTitle' } },
          //{ bidder: 'richaudience', params: { pid: '6yQQzMZSfj', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 841689 } },
          //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_mobile_NEXT_Leader_bellowTitle_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116595} },
          { bidder: 'sharethrough', params: {pkey: 'v233h5pvqZQBNDDGtcDDVINb'} },
          { bidder: 'sharethrough', params: {pkey: 'DgGYBG0o7NEfsUntuVxSsIo6'} }
        ]
      },
      {
        "condition": window.screen.width < 812,
        "id": "MPUTopRight",
        "name": MPUTopRightUnitName,
        "path": `/76730613/${MPUTopRightPathway}`,
        "sizes": [[300, 250],[336,280],[320,100]],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'MPUTopRight', environment:'mobile', placement:`${MPUTopRightPathway}` } },
          { bidder: 'adform', params: { mid: 951058 } },
          { bidder: 'appnexus', params: { placementId: '20701256' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'districtm', params: { placementId: 16005279 } },
          //{ bidder: 'districtmDMX', params: { dmxid: 367605, memberid : 101868 } },
          { bidder: 'ix',  params: { siteId: 607964, size: [300, 250] } },
          { bidder: 'ix',  params: { siteId: 607964, size: [336, 280] } },
          { bidder: 'ix',  params: { siteId: 607964, size: [320, 100] } },
          // //{ bidder: 'justpremium', params: {zone: 115711} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '540973681', delDomain: 'lqr-d.openx.net' } },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_next_MPUTopRight' } },
          //{ bidder: 'richaudience', params: { pid: 'hoS2bUVNvb', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 841694 } },
          //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          { bidder: 'teads', params: { pageId: '113724', placementId: '135707'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_mobile_NEXT_MPUTopRight_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116581} },
          { bidder: 'sharethrough', params: {pkey: 'VKJATx3lhdO50dK2xLzzO88D'} },
          { bidder: 'sharethrough', params: {pkey: 'WfdV85iYP41tw7s1YkmbHGyY'} }
        ]
      },
      {
        "condition": window.screen.width > 812,
        "id": "RightColumn",
        "name": RightColumnUnitName,
        "path": `/76730613/${RightColumnPathway}`,
        "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'RightColumn', environment:'desktop', placement:`${RightColumnPathway}` } },
          { bidder: 'adform', params: { mid: 951051 } },
          { bidder: 'appnexus', params: { placementId: '20701259'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'districtm', params: { placementId: 16005296 } },
          //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
          { bidder: 'ix', params: { siteId: 607960, size: [160, 600] } },
          // //{ bidder: 'justpremium', params: {zone: 115711} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '540973682', delDomain: 'lqr-d.openx.net'} },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_next_RightColumnHalfPage' } },
          //{ bidder: 'richaudience', params: { pid: 'HOpAKTXHmc', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 841695 } },
          { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
          { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersDE_NEXT_RightColumnHalfPage_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116582} },
          { bidder: 'sharethrough', params: {pkey: 'PEK2nvmwbb4V9cmkWXltBwcs'} },
          { bidder: 'sharethrough', params: {pkey: 'w1L7JjGF10WWrwc5tP9BcCEy'} },
          { bidder: 'sharethrough', params: {pkey: 'OM27XgIAsucOOU093aXnIQ9i'} },
        ]
      },
      {
        "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeftColumn",
        "name": LeftColumnUnitName,
        "path": `/76730613/${LeftColumnPathway}`,
        "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'LeftColumn', environment:'desktop', placement:`${LeftColumnPathway}` } },
          { bidder: 'adform', params: { mid: 994357 } },
          { bidder: 'appnexus', params: { placementId: '21164981'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: { siteId: 729204, size: [160, 600] } },
          ////{ bidder: 'justpremium', params: {zone: 115711, allow: ["sa"]} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '543829668', delDomain: 'lqr-d.openx.net'} },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_next_LeftColumnHalfPage' } },
          { bidder: 'sovrn', params:{ tagid: 866552 } },
          { bidder: 'sovrn', params:{ tagid: 935371 } },
          { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
          { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersDE_desktop_NEXT_LeftColumnHalfPage_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'LeftColumn', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116578} },
          { bidder: 'sharethrough', params: {pkey: 'S3kzul4XVlhLn7IO265faI29'} }
        ]
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "StickyBanner",
        "name": StickyBannerUnitName,
        "path": `/76730613/${StickyBannerPathway}`,
        "sizes": window.screen.width < 812 ? [[320, 50],[300,50],[360,50],[320,100],[300,100],[360,100]] : [[728, 90]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'StickyBanner', environment:'desktop', placement:`${StickyBannerPathway}` } }, //Change
          { bidder: 'adform', params: { mid: 1090089 } },
          { bidder: 'appnexus', params: { placementId: '22604765'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix',  params: { siteId: 729205, size: [728, 90] } },
          //{ bidder: 'justpremium', params: {zone: 115711, allow: ["ms"]} },
          { bidder: 'openx', params: { unit: '540973685', delDomain: 'lqr-d.openx.net' } },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_NEXT_StickyBanner' } },
          { bidder: 'sovrn', params:{ tagid: 938344 } },
          { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_desktop_NEXT_StickyBannerDesktop_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116583} },
          { bidder: 'sharethrough', params: {pkey: 'xcLYCC0k2t3ZjILEzFJldZsM'} }
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'StickyBanner', environment:'mobile', placement:`${StickyBannerPathway}` } },
          { bidder: 'adform', params: { mid: 951064 } },
          { bidder: 'appnexus', params: { placementId: '20701258' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'districtm', params: { placementId: 18069184} },
          //{ bidder: 'districtmDMX',params: {dmxid: 479166, memberid: 101868} },
          { bidder: 'ix', params: {siteId: 607971, size: [320,50]} },
          { bidder: 'ix', params: {siteId: 607968, size: [300,50] } },
          { bidder: 'ix', params: {siteId: 607968, size: [360,50] } },
          //{ bidder: 'ix', params: {siteId: 607971, size: [320,50]} },
          //{ bidder: 'justpremium', params: {zone: 115711, allow: ["ms"]} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '540973687', delDomain: 'lqr-d.openx.net' } },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_next_StickyBannerMobile' } },
          //{ bidder: 'richaudience', params: { pid: '7DEfe5PFrk', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 841938 } },
          //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          { bidder: 'teads', params: {pageId: '113724', placementId: '131125'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_NEXT_StickyBannerMobile_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116600} },
          { bidder: 'sharethrough', params: {pkey: '14pVBaFYZkUn8ibqhTzgce5k'} },
          { bidder: 'sharethrough', params: {pkey: 'Be5da6Z6Aj23REUEvUPhkruk'} }
        ]
      },
      {
        "id": "MPUBelowNext",
        "name":  MPUBelowNextUnitName,
        "path": `/76730613/${MPUBelowNextPathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[300, 250],[336,280]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'MPUBelowNext', environment:'desktop', placement:`${MPUBelowNextPathway}` } },
          { bidder: 'adform', params: { mid: 951050 } },
          { bidder: 'appnexus', params: {placementId: '20701260'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 612044, size:[300, 250]} },
          { bidder: 'ix', params: {siteId: 612044, size:[336, 280]} },
          // //{ bidder: 'justpremium', params: {zone: 115711} },
          //{ bidder: 'districtm', params: {placementId: 16152397} },
          //{ bidder: 'districtmDMX', params: {dmxid: 374438, memberid : 101868} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: {unit: '540973683', delDomain: 'lqr-d.openx.net'} },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_next_MPUBelowNext' } },
          //{ bidder: 'richaudience', params: { pid: 'EGWgVO2XQq', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 841691 } },
          { bidder: 'teads', params: { pageId: '113724', placementId: '135708'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_NEXT_MPUBelowNext_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'MPUBelowNext', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId:116579} },
          { bidder: 'sharethrough', params: {pkey: '9NTMgXAL9aZSOsqbCVu5zzcY'} }
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'MPUBelowNext', environment:'mobile', placement:`${MPUBelowNextPathway}` } },
          { bidder: 'adform', params: { mid: 976817 } },
          { bidder: 'appnexus', params: {placementId: '20701260'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 625162, size:[300, 250]} },
          { bidder: 'ix', params: {siteId: 625162, size:[336, 280]} },
          { bidder: 'ix', params: {siteId: 607968, size: [320,100] } },
          // //{ bidder: 'justpremium', params: {zone: 115711} },
          //{ bidder: 'districtm', params: {placementId: 16152397} },
          //{ bidder: 'districtmDMX', params: {dmxid: 374438, memberid : 101868} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: {unit: '540973683', delDomain: 'lqr-d.openx.net'} },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_next_MPUBelowNext' } },
          //{ bidder: 'richaudience', params: { pid: 'EGWgVO2XQq', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 841691 } },
          //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          { bidder: 'teads', params: { pageId: '113724', placementId: '131125'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_mobile_NEXT_MPUBelowNext_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'MPUBelowNext', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116596} },
          { bidder: 'sharethrough', params: {pkey: '0pfmZucYBx3YdjaCMWgYEMcE'} },
          { bidder: 'sharethrough', params: {pkey: 'rbQBghjimq8awNfqRe3hy7ws'} }
        ]
      },
      {
        "id": "MPUCenterRight",
        "name": MPUCenterRightUnitName,
        "path": `/76730613/${MPUCenterRightPathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100], [1,1]] : [[300, 250],[336,280], [1,1]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'MPUCenterRight', environment:'desktop', placement:`${MPUCenterRightPathway}` } },
          { bidder: 'adform', params: { mid: 951052 } },
          { bidder: 'appnexus', params: { placementId: '20701257' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix',  params: { siteId: 607961, size: [300, 250] } },
          { bidder: 'ix',  params: { siteId: 607961, size: [336, 280] } },
          //{ bidder: 'ix',  params: { siteId: 607961, size: [320, 100] } },
          //{ bidder: 'justpremium', params: {zone: 115711} },
          //{ bidder: 'districtm',  params: { placementId: 16005292 } },
          //{ bidder: 'districtmDMX', params: { dmxid: 367606, memberid : 101868 } },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '540973680', delDomain: 'lqr-d.openx.net' } },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_desktop_next_MPUCenterRight' } },
          //{ bidder: 'richaudience', params: { pid: 'i6doEjb0Cb', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 841693 } },
          { bidder: 'teads', params: { pageId: '113724', placementId: '135708'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_NEXT_MPUCenterRight_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116573} },
          { bidder: 'sharethrough', params: {pkey: 'ozYikHwlXUz01CPhbHcdKAAm'} }
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-de', adUnitElementId: 'MPUCenterRight', environment:'mobile', placement:`${MPUCenterRightPathway}` } },
          { bidder: 'adform', params: { mid: 951059 } },
          { bidder: 'appnexus', params: { placementId: '20701257' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix',  params: { siteId: 607967, size: [300, 250] } },
          { bidder: 'ix',  params: { siteId: 607967, size: [336, 280] } },
          { bidder: 'ix',  params: { siteId: 607967, size: [320, 100] } },
          //{ bidder: 'justpremium', params: {zone: 115711, allow: ["is"]} },
          //{ bidder: 'districtm',  params: { placementId: 16005292 } },
          //{ bidder: 'districtmDMX', params: { dmxid: 367606, memberid : 101868 } },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: { unit: '540973680', delDomain: 'lqr-d.openx.net' } },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_de_mobile_next_MPUCenterRight' } },
          //{ bidder: 'richaudience', params: { pid: 'QLncydO1NV', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 841693 } },
          //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          { bidder: 'teads', params: { pageId: '113724', placementId: '135708'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersDE_mobile_NEXT_MPUCenterRight_Prebid"} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847481' } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1493286'} },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          //{ bidder: 'ogury', params: { assetKey: 'OGY-831F435F9475', adUnitId: 'c7c52770-17e3-013b-8434-3794722aeb85', skipSizeCheck: true } },
          { bidder: 'smartadserver', params: { siteId: 536187, pageId: 1662537, formatId: 116592} },
          { bidder: 'sharethrough', params: {pkey: 'IkpHtL0TJWYP6QmqrdcY0tRe'} },
          { bidder: 'sharethrough', params: {pkey: 'Gwr8M5Pl5tUJiRA7wmtEzlcT'} }
        ]
      }
    ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width > 1440) {
      let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
      let lcMissingIX = adUnits.find(ad => ad.id === 'LeftColumn');
      // Right
      rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 607960, size:[300, 600]} });
      rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 607960, size:[300, 250]} });
      rcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103058 } });
      // Left
      lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 729204, size:[300, 600]} });
      lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 729204, size:[300, 250]} });
      lcMissingIX.bidders.push({ bidder: 'sharethrough', params: {pkey: '0q8cVMuIhLjVmMq6eaVIZFaK'} });
      lcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103060 } });
    }
  return adUnits;
}
